
const cardsData = [
    {
      id: 1,
      title: "Sobre nós",
      description: (
        <>
          <p>Somos um escritório de agentes autônomos de investimentos dedicados a conectar nossos clientes às melhores oportunidades financeiras disponíveis no mercado. Nossa missão é oferecer um atendimento personalizado e centralizado, utilizando nossa expertise para alinhar estratégias eficazes aos objetivos de cada cliente.</p>
          <br/>
          <p> Contamos com uma equipe qualificada, comprometida e apaixonada, que atua com transparência e responsabilidade. Oferecemos soluções integradas e exclusivas, atendendo às demandas de cada cliente com excelência e compromisso. Mais do que um time, somos uma família que valoriza o respeito mútuo e se orgulha de fazer parte da construção do seu futuro financeiro. Essa é a nossa essência e a razão de existirmos: ser a sua referência de confiança no universo dos investimentos.</p>
        </>
      )
        
    },
    {
      id: 2,
      title: "Missão",
      description:
        "Nosso atendimento é baseado em uma visão global do cliente, que leva em conta não apenas as suas necessidades atuais, mas também as expectativas futuras das próximas gerações. Queremos ser mais do que prestadores de serviços, queremos ser parceiros na construção da sua história, do seu legado e das suas conquistas, oferecendo apoio e orientação no presente para que possamos alcançar juntos os melhores resultados no futuro. Essa é a nossa forma de valorizar o nosso cliente.",
    },
    {
      id: 3,
      title: "Valor",
      description:
        "Nós valorizamos o nosso cliente como um parceiro de longo prazo, que merece o melhor atendimento e as melhores soluções. Nós nos preocupamos com o seu bem-estar, o seu crescimento e o seu sucesso. Nós buscamos entender as suas necessidades, os seus sonhos e os seus desafios. Nós oferecemos soluções que respeitam o seu presente e o seu futuro, que contribuem para a sustentabilidade das próximas gerações. Esses são os valores que norteiam o nosso atendimento e que nos motivam a fazer sempre mais e melhor por você.",
    },
  ];


export default cardsData