import { DividerLine } from "../../Components/Common/DividerLine";
import { Footer } from "../../Components/Footer";
import { About } from "../../Sections/About";
import { Contact } from "../../Sections/Contact";
import { HomeSection } from "../../Sections/Home";
import { Soluctions } from "../../Sections/Solutions";
import { Team } from "../../Sections/Team";

export const HomePage = () => {
  return (
    <>
      <HomeSection/>
      <About/>
      <DividerLine />
      <Soluctions/>
      <DividerLine />
      <Team/>
      <DividerLine />
      <Contact/>
      <Footer/>
    </>
  );
}
