import './cookie-policy.css'
import { Divider } from "antd";

export const CookiePolicy = () =>{
    return(
        <div className='cookie-policy-page'> 
            <div className='back_to_home'>
                <span class="material-icons">arrow_back</span> 
                <a href="/">Início</a>
            </div>

            <section id="cookie-policy">
                <h1 className='cookie-policy-tittle top'> Política de cookies para Zenon</h1>
                <Divider style={{backgroundColor:'var(--secondary-color)', width:'16px'}} />
                <section className='section_policy'>
                    <h1 className='section_title'>Política de Cookies</h1>
                    <p className='section_description'>Esta Política de Cookies explica o que são Cookies e como Nós os usamos. Você deve ler esta política para que Você possa entender que tipo de cookies Nós usamos, ou as informações que Nós coletamos usando Cookies e como essas informações são usadas. Esta Política de Cookies foi criada com a ajuda do Gerador de Políticas de Cookies .
                    Os cookies normalmente não contêm nenhuma informação que identifique pessoalmente um usuário, mas as informações pessoais que armazenamos sobre Você podem estar vinculadas às informações armazenadas e obtidas dos Cookies. Para obter mais informações sobre como usamos, armazenamos e mantemos seus dados pessoais seguros, consulte nossa Política de Privacidade.
                    Não armazenamos informações pessoais confidenciais, como endereços de correspondência, senhas de contas, etc. nos cookies que usamos.
                    </p>
                </section>
                <Divider style={{backgroundColor:'var(--secondary-color)'}} />
                <section className='section_policy'>
                    <h1 className='section_title'>Interpretação e Definições</h1>
                    <h2 className='section_sub_title'>Interpretação</h2>
                    <p className='section_description'>As palavras cuja letra inicial é maiúscula têm significados definidos sob as seguintes condições. As seguintes definições terão o mesmo significado independentemente de aparecerem no singular ou no plural.</p>
                    <h2 className='section_sub_title'>Definições</h2>
                    <p className='section_description'>
                        Para efeitos desta Política de Cookies:
                        <ul className='list_definitions'>
                            <li><strong>Empresa</strong> (referida como "a Empresa", "Nós", "Nos" ou "Nosso" nesta Política de Cookies) refere-se à Zenon Assessoria de Investimentos LTDA, Localizada em Jose Mendes Rodrigues, 250 , CEP 86730-000, Astorga - PR .</li>
                            <li><strong>Cookies </strong>  são pequenos arquivos que são colocados no seu computador, dispositivo móvel ou qualquer outro dispositivo por um site, contendo detalhes do seu histórico de navegação naquele site, entre seus muitos usos.</li>
                            <li><strong>O site</strong> se refere ao zenon, acessível a partir de <a href="/">Zenon Assessoria</a></li>
                            <li><strong>Você</strong> significa o indivíduo que acessa ou usa o Site, ou uma empresa, ou qualquer entidade legal em nome da qual tal indivíduo está acessando ou usando o Site, conforme aplicável.</li>
                        </ul>
                    </p>
                </section>
                <Divider style={{backgroundColor:'var(--secondary-color)'}} />
                <section className='section_policy'>
                    <h1 className='section_title'>A utilização dos Cookies</h1>
                    <br />
                    <h2 className='section_sub_title'>Tipo de cookies que usamos</h2>
                    <br />
                    <p>Os cookies podem ser "Persistentes" ou "Sessão". Os Cookies Persistentes permanecem no seu computador pessoal ou dispositivo móvel quando Você fica offline, enquanto os Cookies de Sessão são excluídos assim que Você fecha seu navegador da web.Utilizamos cookies de sessão e persistentes para os propósitos definidos abaixo:</p>
                    <p className='section_description'>
                        <ul className='list_definitions'>
                            <li><strong>Cookies Necessários / Essenciais</strong></li>
                            <li><p><strong>Tipo</strong>: Cookies de sessão </p></li>
                            <li> <strong>Administrado por:</strong> Nós </li>
                            <li> <strong>Finalidade:</strong> Esses Cookies são essenciais para fornecer a Você serviços disponíveis por meio do Website e para permitir que Você use alguns de seus recursos. Eles ajudam a autenticar usuários e evitar o uso fraudulento de contas de usuários. Sem esses Cookies, os serviços que Você solicitou não podem ser fornecidos, e Nós somente usamos esses Cookies para fornecer a Você esses serviços. </li> 
                        </ul>
                        <Divider style={{backgroundColor:'var(--secondary-color)'}} />
                        <ul className='list_definitions'>
                            <li><strong>Cookies de funcionalidade</strong></li>
                            <li><p><strong>Tipo</strong>: Cookies persistentes </p></li>
                            <li> <strong>Administrado por:</strong> Nós </li>
                            <li> <strong>Finalidade:</strong> Esses Cookies nos permitem lembrar escolhas que Você faz quando Você usa o Website, como lembrar seus detalhes de login ou preferência de idioma. A finalidade desses Cookies é fornecer a Você uma experiência mais pessoal e evitar que Você tenha que reinserir suas preferências toda vez que Você usar o Website. </li> 
                        </ul>
                    </p>
                </section>
                <Divider style={{backgroundColor:'var(--secondary-color)'}} />
                <section className='section_policy'>
                    <h1 className='section_title'>Suas escolhas em relação aos cookies</h1>
                    <p>Se Você preferir evitar o uso de Cookies no Website, primeiro Você deve desabilitar o uso de Cookies no seu navegador e então excluir os Cookies salvos no seu navegador associados a este website. Você pode usar esta opção para impedir o uso de Cookies a qualquer momento.Se você não aceitar nossos cookies, poderá enfrentar alguns inconvenientes no uso do site e alguns recursos poderão não funcionar corretamente.</p>
                    <br />
                    <p>Se você quiser excluir cookies ou instruir seu navegador para excluir ou recusar cookies, visite as páginas de ajuda do seu navegador.</p>
                    <p className='section_description'>
                        <ul className='list_definitions'>
                            <li><strong>Para o navegador Chrome</strong> visite esta <a href="https://support.google.com/accounts/answer/32050">página do Google</a> </li>
                            <li><strong>Para o navegador Internet Explorer</strong> visite esta <a href="http://support.microsoft.com/kb/278835">página da Microsoft</a> </li>
                            <li><strong>Para o navegador Firefox</strong> visite esta <a href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored">página da Mozilla</a> </li>
                            <li><strong>Para o navegador Safari</strong> visite esta <a href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac">página da Apple</a> </li>
                        </ul>
                        <p>Para qualquer outro navegador, visite as páginas oficiais do seu navegador.</p>                    
                    </p>
                </section>
                <Divider style={{backgroundColor:'var(--secondary-color)'}} />
                <section className='section_policy'>
                    <h1 className='section_title'>Contate-nos</h1>
                    <p>Se você quiser excluir cookies ou instruir seu navegador para excluir ou recusar cookies, visite as páginas de ajuda do seu navegador.</p>
                    <br />
                    <p>Se você tiver alguma dúvida sobre esta Política de Cookies, pode entrar em contato conosco:</p>
                    <p className='section_description'>
                        <ul className='list_definitions'>
                            <li><strong>Por e-mail:</strong> contato@zenoncapital.com.br </li>
                            <li><strong>Por Telefone:</strong> (44) 3218 2300</li>
                        </ul>
                    </p>
                </section>
                <Divider style={{backgroundColor:'var(--secondary-color)'}} />
                <div>Zenon Assessoria de Investimentos LTDA ©</div>
                <br />
            </section>
        </div>
    )
}

