import React, { useState, useEffect } from 'react';
import './header-styles.css';
import menuItems from '../../data/menuItems';
import { Drawer } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { Link } from 'react-scroll';
import logo from '../../assets/logo.png'

export function Header() {
    const [open, setOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <header className={`header ${isScrolled ? '' : 'header--transparent'}`}>
            <img src={logo} className='header__logo'  alt="logo zenon" />
            <ul className='header_divider'>
                {menuItems.map((menu) => (
                    <li key={menu.id}>
                        <Link
                            className='drawer_divider__item'
                            to={menu.tittle} 
                            smooth={true}
                            offset={menu.offset}
                            duration={1000}
                        >
                            {menu.label}
                        </Link>
                    </li>
                ))}
            </ul>

            <div className='button_section'>
                <MenuOutlined className='button_menu' onClick={showDrawer} />
                <Drawer
                    closable={false}

                    className="drawer"
                    placement={'top'}
                    height='50%'
                    width='100%'
                    onClose={onClose}
                    open={open}
                    style={{ boxShadow: '1px 2px 3px var(--background)', borderBottom: '1px solid var(--text-color-black)', backgroundColor: 'var(--background)', fontFamily: 'var(--font)', color: 'var(--text-color-white)' }}
                >
                    <ul className='header_divider_drawer'>
                        {menuItems.map((menu) => (
                            <li key={menu.id} className='drawer_divider__item'>
                                <Link
                                    className='drawer_divider__item'
                                    onClick={onClose}
                                    to={menu.tittle} 
                                    offset={menu.offset}
                                    smooth={true}
                                    duration={1000}
                                >
                                    {menu.label}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </Drawer>
            </div>
        </header>
    );
}
