import './not-found.css'

export const PageNotFound = () => {
    return(
        <main className="page-not-found">
            <div  className="page-not-found-message">
                <h1>Página não encontrada</h1>
                <div className='page-not-found-back'>
                    <span className='material-icons'>arrow_back</span>
                    <h2 className='page-not-found-back-text'>  <a href="/">Voltar ao inicio</a></h2>
                </div>
            </div>
        </main>
    )
}