import { Button } from 'antd'; 
import './contact-styles.css'
import wpp from '../../assets/whatsapp.png'


export const ContactButton = ({link}) =>{
    const handleClick = () => {
        window.open( link, "_blank");   
    };
  
    return(
        <Button 
            className="contact_button" 
            style={{ backgroundColor: "#4BCA59", borderColor: "#4BCA59", color: "#fff" }}
            onClick={handleClick}
        >
            <img className="social_logo" src={wpp} alt="logo whatsapp" />
            <span>Entre em contato</span>
        </Button>

    )
}
