import investimento from '../assets/icones/servico__investimento.png'
import patrimonio from '../assets/icones/servico__planejamento_patrimonio.png'
import sucessorio_familiar from '../assets/icones/servico__planejamento_sucessorio_familiar.png'
import planejamento_risco from '../assets/icones/servico__planejamento_risco.png'
import planejamento_fluxo from '../assets/icones/servico__planejamento_fluxo_caixa.png'

const servicesData= [
    {
        id : 1, 
        icon : investimento,
        tittle : 'Investimentos',
        decription : 'Atendemos pessoas físicas com diversas opções de investimentos para alcançar suas metas financeiras: Renda Fixa, Compromissadas (isento de IOF), Renda Variável, Proteção (hedge), Fundos de Investimentos, Previdência, Câmbio, Tesouro Direto, Fundos Imobiliários, Ofertas Públicas, entre outros. ',
        type: 'to_person',
    } ,
    {
        id : 2, 
        icon : patrimonio,
        tittle : 'Seguros',
        decription : 'Soluções de seguros desenvolvidas para proteger sua família e ajudar na preservação do patrimônio, oferecendo tranquilidade para o futuro. ',
        type: 'to_person',
    } ,
    {
        id : 3, 
        icon : sucessorio_familiar,
        tittle : 'Assessoria Personalizada',
        decription : 'Oferecemos uma análise detalhada de seus objetivos e criamos estratégias de investimentos alinhadas ao seu perfil de investidor.',
        type: 'to_person',
    } ,
    ////empresa
    {
        id : 4, 
        icon : planejamento_risco,
        tittle : 'Investimentos',
        decription : 'Atendemos empresas com soluções de investimentos pensadas para o crescimento e gestão eficiente de recursos financeiros: Renda Fixa, Compromissadas (isento de IOF), Renda Variável, Proteção (hedge), Fundos de Investimentos, Previdência, Câmbio, Tesouro Direto, Fundos Imobiliários, Ofertas Públicas, entre outros.',
        type: 'to_business',
    } ,
    {
        id : 5, 
        icon : planejamento_fluxo,
        tittle : 'Seguros',
        decription : 'Soluções de seguros personalizadas para proteger o patrimônio e assegurar a continuidade das operações da sua empresa. ',
        type: 'to_business',
    } ,
    {
        id : 6, 
        icon : planejamento_fluxo,
        tittle : 'Assessoria Personalizada ',
        decription : 'Oferecemos uma análise estratégica para sua empresa e criamos planos de investimento sob medida, alinhados ao perfil e às metas corporativas. ',
        type: 'to_business',
    } ,
    {
        id : 5, 
        icon : planejamento_fluxo,
        tittle : (
        <div>Opções de Crédito
            <br/>
         e de Fluxo de Caixa</div>
        ),
        decription : (
            <ul className='lista_services'>
                <li>Compromissadas (isento de IOF)</li>
                <li>Financiamento e Crédito</li>
                <ol className='financiamento_e_credito'>
                    <li>Home Equity (crédito com garantia de imóvel) </li>
                    <li>Antecipação de recebíveis </li>
                    <li>Consórcio PJ </li>
                    <li>Financiamento Imobiliário (crédito com garantia)</li>
                </ol>
            </ul>
        ),
        type: 'to_business',
    },
    {
        id : 5, 
        icon : planejamento_fluxo,
        tittle : 'Proteção e Planejamento Empresarial',
        decription : (
            <ul className='lista_services'>
                <li>Seguros PJ </li>
                <li>Recuperação Tributária</li>
                <li>Planejamento Tributário</li>
                <li>Planejamento Fiscal</li>
            </ul>
        ),
        type: 'to_business',
    },
    {
        id : 5, 
        icon : planejamento_fluxo,
        tittle : 'Mercado Financeiro e Energia',
        decription :  (
            <ul className='lista_services'>
                <li>Mercado de Capitais</li>
                <li>Câmbio e Derivativos</li>
                <li>Mercado Livre de Energia</li>
            </ul>
        ),       
        type: 'to_business',
    } ,
    
]

export default servicesData