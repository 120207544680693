import React, { useEffect, useRef, useState } from "react";
import './footer-styles.css'

export function Footer(){
    const [isVisible, setIsVisible] = useState(false);
    const contact = useRef(null);

    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setIsVisible(true);
            } else {
              setIsVisible(false);
            }
          });
        },
        { threshold: 0}
      );
  
      observer.observe(contact.current);
      
      return () => {
        observer.disconnect();
      };
    }, []);
  
    const visible = `${isVisible ? ' show' : ' hidden'}`;

    return(
        <footer className='footer'>
            <div className="footer_aditional_infos">
              <div class="footer__logo"/>                
              <p className="footer__copy">
                <div>Jose Mendes Rodrigues, 250 , CEP 86730-000, Astorga - PR </div>
                <div>© Zenon investimentos - 2024 |  Todos direitos reservados.</div>
                <a className="footer_cookies" href="/politica-cookies" target="_blank">Politica de cookies</a>
              </p>
              <p >Site por: <a className="footer__develped_by" rel="noreferrer" href="https://www.linkedin.com/in/david-gama-dev/" target="_blank">David Serrate</a> </p>
            </div>
            <p  className={`footer__zennon ${visible}`} ref={contact} >A Zenon Assessoria de Investimentos LTDA, inscrita sob o CNPJ: 53.553.899/0001-36, é uma empresa de Assessoria de Investimento devidamente registrada na Comissão de Valores Mobiliários na forma da Resolução CVM 178/23 (“Sociedade”), que mantém contrato de distribuição de produtos financeiros com a XP Investimentos Corretora de Câmbio, Títulos e Valores Mobiliários S.A. (“XP”) e pode, por conta e ordem dos seus clientes, operar no mercado de capitais segundo a legislação vigente. Na forma da legislação da CVM, o Assessor de Investimento não pode administrar ou gerir o patrimônio de investidores. O investimento em ações é um investimento de risco e rentabilidade passada não é garantia de rentabilidade futura. Na realização de operações com derivativos existe a possibilidade de perdas superiores aos valores investidos, podendo resultar em significativas perdas patrimoniais A Sociedade poderá exercer atividades complementares relacionadas aos mercados financeiro, securitário, de previdência e capitalização, desde que não conflitem com a atividade de assessoria de investimentos, podendo ser realizada por meio da pessoa jurídica acima descrita ou por meio de pessoa jurídica terceira. Todas as atividades são prestadas mantendo a devida segregação e em cumprimento ao quanto previsto nas regras da CVM ou de outros órgãos reguladores e autorreguladores. Para informações e dúvidas sobre produtos, contate seu assessor de investimentos. Para reclamações, contate a Ouvidoria da XP pelo telefone 0800 722 3730. </p>

        </footer>
    )
}