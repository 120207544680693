import { BrowserRouter, Route, Routes } from "react-router-dom"
import { HomePage } from "./pages/home"
import { CookiePolicy } from "./pages/cookie_policy"
import { PageNotFound } from "./pages/not-found"

export const RoutesZenon = () =>{
    return(
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<HomePage />} />
                <Route path='/politica-cookies' element={<CookiePolicy />} />
                <Route path='*' element={<PageNotFound />} />
            </Routes>
        </BrowserRouter>
    )
}