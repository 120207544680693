import { Tittle } from '../../Components/Common/Tittle';
import { ServiceCard } from '../../Components/ServiceCard'
import React from "react";
import servicesData from '../../data/servicesData'
import './solutions-styles.css'

export function Soluctions(){
    const services_to_person = servicesData.filter((service) => service.type === 'to_person')
    const services_to_business = servicesData.filter((service) => service.type === 'to_business')

    return(
        <section id="solucoes">
            <Tittle tittleTag="Nossas soluções" tittleText="Nossas soluções" />
            <div className='services_type_tittle'>Para você</div>
            <div className='services_container'>                   
                {services_to_person.map((service) => 
                    <ServiceCard  key={service.id}
                        icon={service.icon} 
                        tittle={service.tittle} 
                        description={service.decription}
                    />)}
            </div>
            <div className='services_type_tittle'>Para sua empresa</div>
            <p className='services_type_subtittle'>
                Criamos estratégias sob medida para atender às necessidades do seu negócio, com eficiência e rapidez, superando os entraves dos processos bancários tradicionais. 
            </p>
            <div className='services_container'>    
                {services_to_business.map((service) => 
                    <ServiceCard  key={service.id}
                        icon={service.icon} 
                        tittle={service.tittle} 
                        description={service.decription}
                    /> )}
            </div>
            <p className='services_description show'>
                Zenon Capital é um escritório de investimentos credenciado pela XP Investimentos. Atuamos com assessoria estratégica para famílias e empresas, auxiliando na maximização de recursos financeiros e na conquista de objetivos de crescimento e rentabilidade.
            </p>
                    
        </section>
    )
}