import React from "react";
import './tittle-styles.css'

export function Tittle({ tittleTag, tittleText, type, color }) {
    if (type === 'unique') {
        return (
            <div className="tittle_section">
                <h2 className="tittle_text_primary" style={{color:color}}>{tittleTag}<span className="underline"></span></h2>
            </div>
        )
    }  
    
    return (
        <div className="tittle_tag">
            <div className="tittle_text">{tittleText}</div>
        </div>
    )
     
}
