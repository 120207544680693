import { ContactButton } from '../../Components/Contact'
import './team-styles.css'

export const Team = () => {
    return(
        <section id='team'>
            <div className='team_section_tittle'>Nosso time</div>
            <div className='team_section'>
                <div className='team_person'>
                    <h2 className='team_person_tittle'>Isadora Carvalho </h2>
                    <h3>Head de expansão </h3>
                </div>
                <div className='team_person'>
                    <h2 className='team_person_tittle'>Douglas Ramos </h2>
                    <h3>Sócio e assessor de investimentos</h3>                  
                </div>
                <div className='team_person'>
                    <h2 className='team_person_tittle'>Hector Ravel </h2>
                    <h3>Head de expansão</h3>
                </div>
            </div>
            <ContactButton link="https://wa.me/message/66UDOHI4OQV7C1" />
        </section>    
    )
}